/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.11.27.18.54
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TopologyLineStringGeom } from './TopologyLineStringGeom';
import {
    TopologyLineStringGeomFromJSON,
    TopologyLineStringGeomFromJSONTyped,
    TopologyLineStringGeomToJSON,
} from './TopologyLineStringGeom';

/**
 * 
 * @export
 * @interface TopologyRoad
 */
export interface TopologyRoad {
    /**
     * 
     * @type {number}
     * @memberof TopologyRoad
     */
    gid: number;
    /**
     * 
     * @type {TopologyLineStringGeom}
     * @memberof TopologyRoad
     */
    geom: TopologyLineStringGeom;
    /**
     * 
     * @type {number}
     * @memberof TopologyRoad
     */
    lengthM: number;
    /**
     * 
     * @type {number}
     * @memberof TopologyRoad
     */
    source: number;
    /**
     * 
     * @type {number}
     * @memberof TopologyRoad
     */
    target: number;
    /**
     * 
     * @type {number}
     * @memberof TopologyRoad
     */
    speed: number;
    /**
     * 
     * @type {number}
     * @memberof TopologyRoad
     */
    cost: number;
    /**
     * 
     * @type {number}
     * @memberof TopologyRoad
     */
    reverseSpeed: number;
    /**
     * 
     * @type {number}
     * @memberof TopologyRoad
     */
    reverseCost: number;
}

/**
 * Check if a given object implements the TopologyRoad interface.
 */
export function instanceOfTopologyRoad(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "gid" in value;
    isInstance = isInstance && "geom" in value;
    isInstance = isInstance && "lengthM" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "target" in value;
    isInstance = isInstance && "speed" in value;
    isInstance = isInstance && "cost" in value;
    isInstance = isInstance && "reverseSpeed" in value;
    isInstance = isInstance && "reverseCost" in value;

    return isInstance;
}

export function TopologyRoadFromJSON(json: any): TopologyRoad {
    return TopologyRoadFromJSONTyped(json, false);
}

export function TopologyRoadFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopologyRoad {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gid': json['gid'],
        'geom': TopologyLineStringGeomFromJSON(json['geom']),
        'lengthM': json['length_m'],
        'source': json['source'],
        'target': json['target'],
        'speed': json['speed'],
        'cost': json['cost'],
        'reverseSpeed': json['reverse_speed'],
        'reverseCost': json['reverse_cost'],
    };
}

export function TopologyRoadToJSON(value?: TopologyRoad | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gid': value.gid,
        'geom': TopologyLineStringGeomToJSON(value.geom),
        'length_m': value.lengthM,
        'source': value.source,
        'target': value.target,
        'speed': value.speed,
        'cost': value.cost,
        'reverse_speed': value.reverseSpeed,
        'reverse_cost': value.reverseCost,
    };
}

