import { LatLng } from "leaflet";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, ScreenState } from "../../Types";
import { ScenarioTypeEnum } from "../../client/lib/models";

export class ScenarioResultStateHandler {
  // result screen
  initScenarioResultScreen(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    // Navigate to this screen and reset the info
    this.state.screenState = ScreenState.ScenarioResult;
    this.state.scenarioScreen.name = null;
    this.state.scenarioScreen.category = null;
    this.state.scenarioScreen.scenarioType = null;
    this.state.scenarioScreen.scenarioTypeInDutch = "";
    this.state.scenarioScreen.resultBlocks = [];
    this.state.scenarioScreen.calculationFinished = false;
    this.state.scenarioScreen.upToDate = false;
    updateState(this.state);

    // Fetch scenario info and result blocks
    this.fetchScenarioContent(updateState);
  }

  fetchScenarioContent(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    if (this.state.selectedScenario) {
      this.scenarioApi
        .apiScenarioRetrieve({
          id: this.state.selectedScenario,
        })
        .then((response) => {
          // Update the scenario information
          this.state.scenarioScreen.name = response.name;
          this.state.scenarioScreen.category = response.category;
          this.state.scenarioScreen.scenarioType = response.scenarioType;
          this.state.newScenario.scenarioType = response.scenarioType;
          switch (this.state.scenarioScreen.scenarioType) {
            case ScenarioTypeEnum.BicycleOrientedDevelopment:
              this.state.scenarioScreen.scenarioTypeInDutch =
                "Fietsgericht ontwikkelen";
              break;
            case ScenarioTypeEnum.TravelTimeIsochrones:
              this.state.scenarioScreen.scenarioTypeInDutch =
                "Bereikbaarheid in beeld";
              break;
          }
          this.state.scenarioScreen.latLng = new LatLng(
            response.projectAreaCentroidLat,
            response.projectAreaCentroidLon
          );
          this.state.scenarioScreen.resultBlocks = response.resultBlockList;
          this.state.scenarioScreen.calculationFinished =
            response.calculationFinished;
          this.state.scenarioScreen.upToDate = response.upToDate;
          updateState(this.state);

          if (!this.state.scenarioScreen.calculationFinished) {
            setTimeout(() => {
              this.fetchScenarioContent(updateState);
            }, 5000);
          }
        });
    }
  }

  scenarioRestartCalculation(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    if (this.state.selectedScenario) {
      this.scenarioApi
        .apiScenarioCalcCreate({ id: this.state.selectedScenario })
        .then((result) => {
          this.displayAlert("Scenario berekening gestart.", 5000, updateState);
          this.state.scenarioScreen.calculationFinished = false;
          this.state.scenarioScreen.upToDate = false;
          updateState(this.state);
          this.fetchScenarioContent(updateState);
        })
        .catch((error) => {
          console.log("Error while starting calculation", error);
          this.displayAlert(
            "Fout tijdens het starten van de scenario berekening.",
            5000,
            updateState
          );
        });
    }
  }

  exportBlock(
    this: AppStateHandler,
    blockId: number,
    bloockName: string,
    updateState: (newState: AppStateType) => void
  ) {
    const alert_id = this.addToAlerts(
      "🖼️ Bezig met het voorbereiden van uw export!",
      updateState
    );
    updateState(this.state);
    this.resultBlockApi
      .apiResultblockExportRetrieve({ id: blockId })
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download =
          blockId +
          "_" +
          this.state.scenarioScreen.name?.replace(/ /g, "_") +
          "_" +
          bloockName.replace(/ /g, "_") +
          ".png";
        document.body.appendChild(link);
        link.dispatchEvent(
          new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
          })
        );
        document.body.removeChild(link);
        this.removeFromAlerts(alert_id, updateState);
      });
  }

  deleteScenario(
    this: AppStateHandler,
    updateState: (newState: AppStateType) => void
  ) {
    this.scenarioApi
      .apiScenarioDestroy({ id: this.state.selectedScenario as number })
      .then((response) => {
        this.state.projects = [];
        this.loadProjects(() => {
          this.initScenarioResultScreen(updateState);
        });
        updateState(this.state);
      });
  }
}
