import React from "react";
import { BiNetworkChart } from "react-icons/bi";
import { FaMinus, FaPlus } from "react-icons/fa";
import { MdOutlineDone } from "react-icons/md";
import { RiSpeedUpLine } from "react-icons/ri";
import { TbReload } from "react-icons/tb";
import ChangeSpeedPopUp from "src/components/ChangeSpeedPopUp";
import EditNetworkMap from "src/components/EditNetworkMap";
import MainButton from "src/components/MainButton";
import MenuTitle from "src/components/MenuTitle";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType, DrawMode } from "../../Types";
import "./EditNetworkScreen.css";

interface EditNetworkScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class EditNetworkScreen extends React.Component<EditNetworkScreenProps> {
  render() {
    return (
      <>
        {(() => {
          if (this.props.state.editNetwork.editSpeedPopUpVisable) {
            return (
              <ChangeSpeedPopUp
                changeRoadSpeedForm={{
                  speed: this.props.state.editNetwork.roadsToUpgrade.speed,
                  reverseSpeed:
                    this.props.state.editNetwork.roadsToUpgrade.reverseSpeed,
                }}
                updateSpeed={(formData) => {
                  this.props.stateHandler.editNetworkUpdateSpeed(
                    formData,
                    this.props.updateState
                  );
                }}
                saveRoad={() => {
                  this.props.stateHandler.editNetworkSaveRoad(
                    this.props.updateState
                  );
                }}
              ></ChangeSpeedPopUp>
            );
          }
        })()}

        <div id="EditNetworkScreen">
          <div id="EditNetworkScreen-Content">
            <div className="top-bar">
              <ul>
                <li>
                  <MenuTitle
                    title={"Netwerk bewerken"}
                    icon={<BiNetworkChart size={19} />}
                  />
                </li>
                <li>
                  <MainButton
                    onClick={() => {
                      this.props.stateHandler.reloadBaseNetwork(
                        this.props.updateState
                      );
                    }}
                    icon={<TbReload size={12} />}
                    className={
                      this.props.state.editNetwork.selectedTopology.id !== null
                        ? "MainBtn"
                        : "MainBtn disabled"
                    }
                  >
                    Netwerk herladen
                  </MainButton>
                </li>
                <li>
                  <MainButton
                    onClick={() => {
                      this.props.stateHandler.editNetworkMapChangeDrawMode(
                        this.props.state.editNetwork.drawMode ===
                          DrawMode.AddRoad
                          ? DrawMode.Off
                          : DrawMode.AddRoad,
                        this.props.updateState
                      );
                    }}
                    icon={<FaPlus size={12} />}
                    className={
                      this.props.state.editNetwork.drawMode === DrawMode.AddRoad
                        ? "MainBtn selected"
                        : this.props.state.editNetwork.selectedTopology.roads
                            .length > 0
                        ? "MainBtn"
                        : "MainBtn disabled"
                    }
                  >
                    Toevoegen
                  </MainButton>
                </li>
                <li>
                  <MainButton
                    onClick={() => {
                      this.props.stateHandler.editNetworkMapChangeDrawMode(
                        this.props.state.editNetwork.drawMode ===
                          DrawMode.EditRoad
                          ? DrawMode.Off
                          : DrawMode.EditRoad,
                        this.props.updateState
                      );
                    }}
                    icon={<RiSpeedUpLine size={12} />}
                    className={
                      this.props.state.editNetwork.drawMode ===
                      DrawMode.EditRoad
                        ? "MainBtn selected"
                        : this.props.state.editNetwork.selectedTopology.roads
                            .length > 0
                        ? "MainBtn"
                        : "MainBtn disabled"
                    }
                  >
                    Snelheid wijzigen
                  </MainButton>
                </li>
                <li>
                  <MainButton
                    onClick={() => {
                      this.props.stateHandler.editNetworkMapChangeDrawMode(
                        this.props.state.editNetwork.drawMode ===
                          DrawMode.AddVertex
                          ? DrawMode.Off
                          : DrawMode.AddVertex,
                        this.props.updateState
                      );
                    }}
                    icon={<FaPlus size={12} />}
                    className={
                      this.props.state.editNetwork.drawMode ===
                      DrawMode.AddVertex
                        ? "MainBtn selected"
                        : this.props.state.editNetwork.selectedTopology.vertices
                            .length > 0
                        ? "MainBtn"
                        : "MainBtn disabled"
                    }
                  >
                    Knooppunt toevoegen
                  </MainButton>
                </li>
                {/* <li>
                  <MainButton
                    onClick={() => {
                      this.props.stateHandler.editNetworkMapChangeDrawMode(
                        this.props.state.editNetwork.drawMode ===
                          DrawMode.DeleteRoad
                          ? DrawMode.Off
                          : DrawMode.DeleteRoad,
                        this.props.updateState
                      );
                    }}
                    icon={<FaMinus size={12} />}
                    className={
                      this.props.state.editNetwork.drawMode ===
                      DrawMode.DeleteRoad
                        ? "MainBtn selected"
                        : this.props.state.editNetwork.selectedTopology.roads
                            .length > 0
                        ? "MainBtn"
                        : "MainBtn disabled"
                    }
                  >
                    Verwijder aangemaakt
                  </MainButton>
                </li> */}
                <li>
                  <MainButton
                    onClick={() => {
                      this.props.stateHandler.editNetworkMapChangeDrawMode(
                        this.props.state.editNetwork.drawMode ===
                          DrawMode.DeleteRoad
                          ? DrawMode.Off
                          : DrawMode.DeleteRoad,
                        this.props.updateState
                      );
                    }}
                    icon={<FaMinus size={12} />}
                    className={
                      this.props.state.editNetwork.drawMode ===
                      DrawMode.DeleteRoad
                        ? "MainBtn selected"
                        : this.props.state.editNetwork.selectedTopology.roads
                            .length > 0
                        ? "MainBtn"
                        : "MainBtn disabled"
                    }
                  >
                    Verwijderen
                  </MainButton>
                </li>
                <li>
                  <MainButton
                    onClick={() => {
                      this.props.stateHandler.editNetworkStartCalculation(
                        this.props.updateState
                      );
                    }}
                    icon={<MdOutlineDone size={12} />}
                    className={
                      this.props.state.editNetwork.editedRoads.length > 0
                        ? "MainBtn OK"
                        : "MainBtn disabled"
                    }
                  >
                    Bereken scenario
                  </MainButton>
                </li>
              </ul>
            </div>
            <div id="EditNetworkScreen-MapHolder">
              <EditNetworkMap
                state={this.props.state}
                stateHandler={this.props.stateHandler}
                updateState={this.props.updateState}
              />
            </div>
          </div>

          {(() => {
            if (this.props.state.editNetwork.roadInfo.length > 0) {
              return (
                <div id="EditNetworkScreen-RoadInfo">
                  <table id="EditNetworkScreen-Table">
                    <tbody>
                      {this.props.state.editNetwork.roadInfo.map((i) => (
                        <tr key={i.attribute} id="EditNetworkScreen-Item">
                          <td id="EditNetworkScreen-Attribute">
                            {i.attribute}
                          </td>
                          <td>{i.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              );
            }
          })()}
        </div>
      </>
    );
  }
}
