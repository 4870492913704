/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.11.27.18.54
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Login,
  LoginResponse,
  Logout,
} from '../models';
import {
    LoginFromJSON,
    LoginToJSON,
    LoginResponseFromJSON,
    LoginResponseToJSON,
    LogoutFromJSON,
    LogoutToJSON,
} from '../models';

export interface AuthLoginCreateRequest {
    login: Login;
}

export interface AuthLogoutCreateRequest {
    logout: Logout;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Login user
     */
    async authLoginCreateRaw(requestParameters: AuthLoginCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        if (requestParameters.login === null || requestParameters.login === undefined) {
            throw new runtime.RequiredError('login','Required parameter requestParameters.login was null or undefined when calling authLoginCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginToJSON(requestParameters.login),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Login user
     */
    async authLoginCreate(requestParameters: AuthLoginCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.authLoginCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Logout user
     */
    async authLogoutCreateRaw(requestParameters: AuthLogoutCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.logout === null || requestParameters.logout === undefined) {
            throw new runtime.RequiredError('logout','Required parameter requestParameters.logout was null or undefined when calling authLogoutCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/logout/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LogoutToJSON(requestParameters.logout),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Logout user
     */
    async authLogoutCreate(requestParameters: AuthLogoutCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authLogoutCreateRaw(requestParameters, initOverrides);
    }

}
