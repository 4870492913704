/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.11.27.18.54
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ADD` - ADD
 * * `EDIT` - EDIT
 * * `DELETE` - DELETE
 * @export
 */
export const EditTypeEnum = {
    Add: 'ADD',
    Edit: 'EDIT',
    Delete: 'DELETE'
} as const;
export type EditTypeEnum = typeof EditTypeEnum[keyof typeof EditTypeEnum];


export function EditTypeEnumFromJSON(json: any): EditTypeEnum {
    return EditTypeEnumFromJSONTyped(json, false);
}

export function EditTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditTypeEnum {
    return json as EditTypeEnum;
}

export function EditTypeEnumToJSON(value?: EditTypeEnum | null): any {
    return value as any;
}

