/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.11.27.18.54
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VertexPointGeom } from './VertexPointGeom';
import {
    VertexPointGeomFromJSON,
    VertexPointGeomFromJSONTyped,
    VertexPointGeomToJSON,
} from './VertexPointGeom';

/**
 * 
 * @export
 * @interface VertexResponse
 */
export interface VertexResponse {
    /**
     * 
     * @type {number}
     * @memberof VertexResponse
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof VertexResponse
     */
    gid: number;
    /**
     * 
     * @type {VertexPointGeom}
     * @memberof VertexResponse
     */
    geom: VertexPointGeom;
}

/**
 * Check if a given object implements the VertexResponse interface.
 */
export function instanceOfVertexResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "gid" in value;
    isInstance = isInstance && "geom" in value;

    return isInstance;
}

export function VertexResponseFromJSON(json: any): VertexResponse {
    return VertexResponseFromJSONTyped(json, false);
}

export function VertexResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VertexResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'gid': json['gid'],
        'geom': VertexPointGeomFromJSON(json['geom']),
    };
}

export function VertexResponseToJSON(value?: VertexResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gid': value.gid,
        'geom': VertexPointGeomToJSON(value.geom),
    };
}

