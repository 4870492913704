import React from "react";
import { FaPlus } from "react-icons/fa";
import { GoProjectRoadmap } from "react-icons/go";
import { GrUserAdmin } from "react-icons/gr";
import { MdAddLocationAlt } from "react-icons/md";
import { RiRoadMapLine } from "react-icons/ri";
import { SlDocs } from "react-icons/sl";
import { AppStateHandler } from "src/AppStateHandler";
import { AppStateType, ScreenState } from "src/Types";
import { ScenarioTypeEnum } from "src/client/lib/models";

import Button from "./Button";
import MenuTitle from "./MenuTitle";
import "./ProjectBar.css";

interface ProjectBarProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class ProjectBar extends React.Component<ProjectBarProps> {
  render() {
    return (
      <div id="ProjectBar">
        <div id="ProjectBar-Logo"></div>

        <div id="ProjectBar-ListHolder">
          <MenuTitle
            title="Projecten"
            onClick={() => {
              this.props.stateHandler.changeScreen(
                ScreenState.NewProject,
                this.props.updateState
              );
            }}
            button={true}
            button_content={<FaPlus size={25} />}
            transparentButtonBackground={false}
          />

          <ul id="ProjectBar-List">
            {this.props.state.projects.map((project) => (
              <li key={project.id} className={"ProjectBar-ProjectItem"}>
                <span
                  className={
                    project.id === this.props.state.selectedProject
                      ? "ProjectBar-ProjectName selected"
                      : "ProjectBar-ProjectName"
                  }
                  onClick={() => {
                    this.props.stateHandler.changeSelectedProject(
                      project.id,
                      this.props.updateState
                    );
                  }}
                >
                  <GoProjectRoadmap /> {project.name}
                </span>

                <ul className="ProjectBar-Sublist">
                  {project.scenarioList.map((scenario) => (
                    <div key={scenario.id} className="ProjectListContainer">
                      <li
                        className={
                          scenario.id === this.props.state.selectedScenario
                            ? "selected"
                            : ""
                        }
                        onClick={() => {
                          this.props.stateHandler.changeSelectedScenario(
                            project.id,
                            scenario.id,
                            this.props.updateState
                          );
                        }}
                      >
                        <RiRoadMapLine />
                        &nbsp;&nbsp;{scenario.name}
                      </li>
                    </div>
                  ))}
                  {(() => {
                    if (
                      project.scenarioList.length > 0 &&
                      project.scenarioType !==
                        ScenarioTypeEnum.TravelTimeIsochrones
                    ) {
                      return (
                        <li
                          onClick={() => {
                            this.props.stateHandler.projectBarCreateNewScenario(
                              project.id,
                              this.props.updateState
                            );
                          }}
                        >
                          <FaPlus /> &nbsp;Nieuw scenario
                        </li>
                      );
                    }
                  })()}
                </ul>
              </li>
            ))}
          </ul>
        </div>
        <div id="ProjectBar-CustomAnalysisLayersSection">
          <Button
            onClick={() => {
              this.props.stateHandler.changeScreen(
                ScreenState.CustomAnalysisLayersScreen,
                this.props.updateState
              );
            }}
          >
            <MdAddLocationAlt /> BESTEMMINGEN
          </Button>
        </div>
        <div id="ProjectBar-DocsSection">
          {this.props.state.current_user.is_staff ? (
            <Button
              onClick={() => {
                this.props.stateHandler.changeScreen(
                  ScreenState.AdminScreen,
                  this.props.updateState
                );
              }}
            >
              <GrUserAdmin /> Admin
            </Button>
          ) : (
            <Button
              onClick={() => {
                this.props.stateHandler.changeScreen(
                  ScreenState.DocsScreen,
                  this.props.updateState
                );
              }}
            >
              <SlDocs /> HANDLEIDING
            </Button>
          )}
        </div>
        <div id="ProjectBar-LOGOUTSection">
          <Button
            onClick={() => {
              this.props.stateHandler.logout(this.props.updateState);
            }}
          >
            {this.props.state.current_user.name?.toUpperCase() + " | "}{" "}
            UITLOGGEN
          </Button>
        </div>
      </div>
    );
  }
}
