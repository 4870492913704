import { LineString, Point } from "geojson";
import { LatLng } from "leaflet";
import {
  CategoryEnum,
  EditTypeEnum,
  HousingTypeEnum,
  ProjectListResponse,
  ResultBlock,
  ScenarioTypeEnum,
  TopologyBoundingBox,
  TopologyRoad,
  TopologyVertice,
  TypeEnum,
  User,
  UserLog,
} from "./client/lib/models";

export enum ScreenState {
  ScenarioResult = "ScenarioResult",
  Project = "Project",
  NewProject = "NewProject",
  NewScenario = "NewScenario",
  EditNetworkScreen = "EditNetworkScreen",
  DocsScreen = "DocsScreen",
  CustomAnalysisLayersScreen = "CustomAnalysisLayersScreen",
  CreateCustomAnalysisLayersScreen = "CreateCustomAnalysisLayersScreen",
  EditHousingPlanAreasScreen = "EditHousingPlanAreasScreen",
  AdminScreen = "AdminScreen",
}

export enum PopUpState {
  Hidden = "Hidden",
  waiting = "waiting",
  welcome = "welcome",
  LEGEND = "LEGEND",
}

export enum DrawMode {
  Off,
  AddRoad,
  EditRoad,
  DeleteRoad,
  AddPoint,
  DeletePoint,
  AddVertex,
}

export interface Road {
  id: number | null;
  gid: number | null;
  edit_type: EditTypeEnum;
  speed: number;
  reverse_speed: number;
  geom: LineString;
  source: number;
  target: number;
}

export interface EditedVertex {
  id: number | null;
  gid: number | null;
  geom: Point;
}
export interface CustomAnalysisLayer {
  id: number;
  name: string;
  destinations: {
    geom: LatLng;
    name: string;
  }[];
  type: TypeEnum;
}
export interface SelectListObject {
  id: number;
  label: string;
  value: string | number;
  active: boolean;
}
export interface HousingPlanArea {
  pk: any;
  coordinates: LatLng[];
  name: string;
  mousePosition: LatLng | null;
  areaClosed: boolean;
  number_of_houses: number;
  housing_type: HousingTypeEnum;
}
export interface AppStateType {
  alerts: { id: number; text: string }[];
  loggedIn: boolean;
  screenState: ScreenState;
  projects: ProjectListResponse[];
  selectedProject: number | null;
  selectedScenario: number | null;
  auth: {
    email: string | null;
    password: string | null;
    accessToken: string | null;
  };
  newProject: {
    form_stage: number;
    name: string | null;
    projectArea: {
      coordinates: LatLng[];
      mousePosition: LatLng | null;
      areaClosed: boolean;
    };
    InitSenarioTypes: SelectListObject[];
    selectedDestinationGroupList: number[];
    destinationGroupList: SelectListObject[];
    selectedOriginDestinationList: number[];
    originDestinationList: SelectListObject[];
    topologyList: SelectListObject[];
    selectedTopology: number | null;
    previousButtonVisable: boolean;
    nextButtonVisable: boolean;
  };
  project: {
    name: string | null;
    typescenario: string | null;
    sourcedataDate: string | null;
    createdDate: string | null;
  };
  scenarioScreen: {
    name: string | null;
    category: CategoryEnum | null;
    scenarioType: ScenarioTypeEnum | null;
    scenarioTypeInDutch: string;
    latLng: LatLng;
    calculationFinished: boolean;
    upToDate: boolean;
    resultBlocks: ResultBlock[];
  };
  newScenario: {
    name: string | null;
    category: CategoryEnum;
    scenarioType: ScenarioTypeEnum;
  };
  current_user: {
    name: string | null;
    email: string | null;
    is_staff: boolean;
    center_area: LatLng | null;
  };
  editNetwork: {
    selectedTopology: {
      id: number | null;
      roads: TopologyRoad[];
      vertices: TopologyVertice[];
      boundingBox: TopologyBoundingBox | null;
    };
    map: {
      zoom: number;
      center_lat: number;
      center_lng: number;
      x_min: number | null;
      y_min: number | null;
      x_max: number | null;
      y_max: number | null;
      update_map: boolean;
    };
    drawMode: DrawMode;
    editedRoads: Road[];
    roadsToUpgrade: {
      gids: Set<number>;
      speed: string;
      reverseSpeed: string;
    };
    editedVertices: EditedVertex[];
    editSpeedPopUpVisable: boolean;
    roadInfo: { attribute: string; value: string }[];
  };

  popUpState: PopUpState;
  Legend: {
    label: string;
    color: string;
  }[];

  newAnalysisLayer: CustomAnalysisLayer;

  customAnalysisLayers: CustomAnalysisLayer[];

  EditHousingPlanAreasScreen: {
    NewHousingPlanAreaPopUpVisable: boolean;
    EditHousingPlanAreaPopUpVisable: boolean;
    SelectedHousingPlanArea: HousingPlanArea | null;
    newHousingPlanArea: HousingPlanArea;
    HousingPlanAreas: HousingPlanArea[];
    EditHousingPlanArea: HousingPlanArea | null;
  };

  newUser: {
    email: string | null;
    password: string | null;
    area_id: number | null;
  };

  listUsers: User[];
  userLogsPopUpVisable: boolean;
  userLogs: UserLog[];
}
