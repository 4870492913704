/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.11.27.18.54
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `TABLE` - TABLE
 * * `GRAPH` - GRAPH
 * * `GRAPH_DIFF` - GRAPH_DIFF
 * * `OVERVIEW` - OVERVIEW
 * * `ISOCHRONE` - ISOCHRONE
 * * `HEATMAP` - HEATMAP
 * * `HEATMAP_DIFF` - HEATMAP_DIFF
 * * `SCENARIO_CHANGES` - SCENARIO_CHANGES
 * * `MUNICIPALITY_GRAPH` - MUNICIPALITY_GRAPH
 * * `MUNICIPALITY_GRAPH_DIFF` - MUNICIPALITY_GRAPH_DIFF
 * * `HOUSING_PLAN_GRAPH` - HOUSING_PLAN_GRAPH
 * * `PUPIL_DENSITY_NETWORK` - PUPIL_DENSITY_NETWORK
 * * `PUPIL_DENSITY_NETWORK_DIFF` - PUPIL_DENSITY_NETWORK_DIFF
 * @export
 */
export const ContentTypeEnum = {
    Table: 'TABLE',
    Graph: 'GRAPH',
    GraphDiff: 'GRAPH_DIFF',
    Overview: 'OVERVIEW',
    Isochrone: 'ISOCHRONE',
    Heatmap: 'HEATMAP',
    HeatmapDiff: 'HEATMAP_DIFF',
    ScenarioChanges: 'SCENARIO_CHANGES',
    MunicipalityGraph: 'MUNICIPALITY_GRAPH',
    MunicipalityGraphDiff: 'MUNICIPALITY_GRAPH_DIFF',
    HousingPlanGraph: 'HOUSING_PLAN_GRAPH',
    PupilDensityNetwork: 'PUPIL_DENSITY_NETWORK',
    PupilDensityNetworkDiff: 'PUPIL_DENSITY_NETWORK_DIFF'
} as const;
export type ContentTypeEnum = typeof ContentTypeEnum[keyof typeof ContentTypeEnum];


export function ContentTypeEnumFromJSON(json: any): ContentTypeEnum {
    return ContentTypeEnumFromJSONTyped(json, false);
}

export function ContentTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentTypeEnum {
    return json as ContentTypeEnum;
}

export function ContentTypeEnumToJSON(value?: ContentTypeEnum | null): any {
    return value as any;
}

