/* tslint:disable */
/* eslint-disable */
/**
 * Scenario Manager API
 * Scenario Manager API
 *
 * The version of the OpenAPI document: 24.11.27.18.54
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ScenarioCreateRequest,
  ScenarioCreateResponse,
  ScenarioInfoResponse,
  ScenarioListResponse,
} from '../models';
import {
    ScenarioCreateRequestFromJSON,
    ScenarioCreateRequestToJSON,
    ScenarioCreateResponseFromJSON,
    ScenarioCreateResponseToJSON,
    ScenarioInfoResponseFromJSON,
    ScenarioInfoResponseToJSON,
    ScenarioListResponseFromJSON,
    ScenarioListResponseToJSON,
} from '../models';

export interface ApiScenarioCalcCreateRequest {
    id: number;
}

export interface ApiScenarioCreateRequest {
    scenarioCreateRequest: ScenarioCreateRequest;
}

export interface ApiScenarioDestroyRequest {
    id: number;
}

export interface ApiScenarioListRequest {
    project: number;
}

export interface ApiScenarioRetrieveRequest {
    id: number;
}

/**
 * 
 */
export class ScenarioApi extends runtime.BaseAPI {

    /**
     * Endpoint for starting the calculation
     */
    async apiScenarioCalcCreateRaw(requestParameters: ApiScenarioCalcCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiScenarioCalcCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/scenario/{id}/calc`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for starting the calculation
     */
    async apiScenarioCalcCreate(requestParameters: ApiScenarioCalcCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiScenarioCalcCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for creating a scenario
     */
    async apiScenarioCreateRaw(requestParameters: ApiScenarioCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScenarioCreateResponse>> {
        if (requestParameters.scenarioCreateRequest === null || requestParameters.scenarioCreateRequest === undefined) {
            throw new runtime.RequiredError('scenarioCreateRequest','Required parameter requestParameters.scenarioCreateRequest was null or undefined when calling apiScenarioCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/scenario/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScenarioCreateRequestToJSON(requestParameters.scenarioCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScenarioCreateResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for creating a scenario
     */
    async apiScenarioCreate(requestParameters: ApiScenarioCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScenarioCreateResponse> {
        const response = await this.apiScenarioCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for deleting an scenario
     */
    async apiScenarioDestroyRaw(requestParameters: ApiScenarioDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiScenarioDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/scenario/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for deleting an scenario
     */
    async apiScenarioDestroy(requestParameters: ApiScenarioDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiScenarioDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for receiving a list of scenario the user has access to
     */
    async apiScenarioListRaw(requestParameters: ApiScenarioListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ScenarioListResponse>>> {
        if (requestParameters.project === null || requestParameters.project === undefined) {
            throw new runtime.RequiredError('project','Required parameter requestParameters.project was null or undefined when calling apiScenarioList.');
        }

        const queryParameters: any = {};

        if (requestParameters.project !== undefined) {
            queryParameters['project'] = requestParameters.project;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/scenario/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScenarioListResponseFromJSON));
    }

    /**
     * Endpoint for receiving a list of scenario the user has access to
     */
    async apiScenarioList(requestParameters: ApiScenarioListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ScenarioListResponse>> {
        const response = await this.apiScenarioListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiving the scenario data
     */
    async apiScenarioRetrieveRaw(requestParameters: ApiScenarioRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScenarioInfoResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiScenarioRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/scenario/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScenarioInfoResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for receiving the scenario data
     */
    async apiScenarioRetrieve(requestParameters: ApiScenarioRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScenarioInfoResponse> {
        const response = await this.apiScenarioRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
