import React from "react";
import MainButton from "src/components/MainButton";
import MenuTitle from "src/components/MenuTitle";
import SectionTitle from "src/components/SectionTitle";
import { TextInput, TextInputType } from "src/components/TextInput";

import { ScenarioTypeEnum } from "src/client/lib/models/ScenarioTypeEnum";
import DrawAreaMap from "src/components/DrawAreaMap";
import { AppStateHandler } from "../../AppStateHandler";
import { AppStateType } from "../../Types";
import "./NewProjectScreen.css";
interface NewProjectScreenProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateState: (newState: AppStateType) => void;
}

export default class NewProjectScreen extends React.Component<NewProjectScreenProps> {
  render() {
    return (
      <div id="NewProjectScreen">
        <div className="top-bar">
          <ul>
            <li>
              <MenuTitle title="Nieuw project" />
            </li>
          </ul>
        </div>
        <div id="NewProjectScreen-ContentHolder">
          {/* Switch statement for different content based on the state */}
          {(() => {
            switch (this.props.state.newProject.form_stage) {
              case 1:
                return (
                  <div className="row">
                    <div>
                      <SectionTitle subtitle={true}>
                        1.Type analyse
                      </SectionTitle>
                      <div>
                        <div className="col">
                          {this.props.state.newProject.InitSenarioTypes.map(
                            (item) => (
                              <div key={item.id} className="row">
                                <label
                                  className={`CheckBoxButton ${
                                    item.active ? "" : "disabled"
                                  }`}
                                >
                                  <input
                                    type="radio"
                                    value={item.value}
                                    checked={
                                      this.props.state.newScenario
                                        .scenarioType === item.value
                                    }
                                    onChange={() => {
                                      this.props.state.newScenario.scenarioType =
                                        item.value as ScenarioTypeEnum;
                                      this.props.updateState(this.props.state);
                                    }}
                                  />
                                  {item.label}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              case 2:
                return (
                  <>
                    <SectionTitle subtitle={true}>2.Projectnaam</SectionTitle>
                    <div id="NewProjectScreen-ProjectAreaInfo">
                      Geef een unieke naam voor dit project
                    </div>

                    <TextInput
                      type={TextInputType.TextSpacesNumbersLines}
                      value={this.props.state.newProject.name}
                      onChange={(newValue) => {
                        this.props.stateHandler.updateNewProjectName(
                          newValue,
                          this.props.updateState
                        );
                      }}
                      placeholder="Projectnaam"
                    ></TextInput>
                  </>
                );
              case 3:
                return (
                  <>
                    <SectionTitle subtitle={true}>
                      3.Teken een project-gebied in
                    </SectionTitle>
                    <div id="NewProjectScreen-ProjectAreaInfo">
                      Zoom in op de kaart. Klik op de kaart om te beginnen met
                      het tekenen van uw gebied. Klik op het beginpunt om het
                      gebied compleet te maken.
                    </div>
                    <DrawAreaMap
                      state={this.props.state}
                      stateHandler={this.props.stateHandler}
                      updateState={this.props.updateState}
                    ></DrawAreaMap>
                    <div style={{ width: "50%" }}>
                      <MainButton
                        onClick={() => {
                          this.props.state.newProject.projectArea.coordinates =
                            [];
                          this.props.state.newProject.projectArea.areaClosed =
                            false;
                          this.props.updateState(this.props.state);
                        }}
                        className={"MainBtn"}
                      >
                        Gebied resetten
                      </MainButton>
                    </div>
                  </>
                );
              case 4:
                if (
                  this.props.state.newScenario.scenarioType ===
                  ScenarioTypeEnum.BicycleOrientedDevelopment
                ) {
                  return (
                    <div className="row">
                      <div>
                        <SectionTitle subtitle={true}>
                          4.Bestemmingen
                        </SectionTitle>

                        <div>
                          <div className="col">
                            {this.props.state.newProject.destinationGroupList.map(
                              (destinationGroup) => (
                                <div key={destinationGroup.id} className="row">
                                  <label
                                    className={`CheckBoxButton ${
                                      destinationGroup.active ? "" : "disabled"
                                    }`}
                                  >
                                    <input
                                      type="checkbox"
                                      value={destinationGroup.value}
                                      checked={this.props.state.newProject.selectedDestinationGroupList.includes(
                                        destinationGroup.id
                                      )}
                                      onChange={() => {
                                        if (
                                          this.props.state.newProject.selectedDestinationGroupList.includes(
                                            destinationGroup.id
                                          )
                                        ) {
                                          this.props.stateHandler.removeSelectedDestinationGroup(
                                            destinationGroup.id,
                                            this.props.updateState
                                          );
                                        } else {
                                          this.props.stateHandler.addSelectedDestinationGroup(
                                            destinationGroup.id,
                                            this.props.updateState
                                          );
                                        }
                                      }}
                                    />
                                    {destinationGroup.label}
                                  </label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else if (
                  this.props.state.newScenario.scenarioType ===
                  ScenarioTypeEnum.OriginDestination
                ) {
                  return (
                    <div className="row">
                      <div>
                        <SectionTitle subtitle={true}>
                          4. Doelgroep & vervoersmiddel
                        </SectionTitle>

                        <div>
                          <div className="col">
                            {this.props.state.newProject.originDestinationList.map(
                              (originDestination) => (
                                <div key={originDestination.id} className="row">
                                  <label
                                    className={`CheckBoxButton ${
                                      originDestination.active ? "" : "disabled"
                                    }`}
                                  >
                                    <input
                                      type="checkbox"
                                      value={originDestination.value}
                                      checked={this.props.state.newProject.selectedOriginDestinationList.includes(
                                        originDestination.id
                                      )}
                                      onChange={() => {
                                        if (
                                          this.props.state.newProject.selectedOriginDestinationList.includes(
                                            originDestination.id
                                          )
                                        ) {
                                          this.props.stateHandler.removeSelectedOriginDestination(
                                            originDestination.id,
                                            this.props.updateState
                                          );
                                        } else {
                                          this.props.stateHandler.addSelectedOriginDestination(
                                            originDestination.id,
                                            this.props.updateState
                                          );
                                        }
                                      }}
                                    />
                                    {originDestination.label}
                                  </label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return <></>;
                }
              case 5:
                if (
                  this.props.state.newScenario.scenarioType ===
                  ScenarioTypeEnum.BicycleOrientedDevelopment
                ) {
                  return (
                    <div className="row">
                      <div>
                        <SectionTitle subtitle={true}>
                          5.Selecteer wegennetwerk
                        </SectionTitle>

                        <div>
                          <div className="col">
                            {this.props.state.newProject.topologyList.map(
                              (topology) => (
                                <div key={topology.id} className="row">
                                  <label
                                    className={`CheckBoxButton ${
                                      topology.active ? "" : "disabled"
                                    }`}
                                  >
                                    <input
                                      type="checkbox"
                                      value={topology.value}
                                      checked={
                                        this.props.state.newProject
                                          .selectedTopology === topology.id
                                      }
                                      onChange={() => {
                                        this.props.state.newProject.selectedTopology =
                                          topology.id;
                                        this.props.updateState(
                                          this.props.state
                                        );
                                      }}
                                    />
                                    {topology.label}
                                  </label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return <></>;
                }
              default:
                return null;
            }
          })()}
          <div className="button-container">
            {(() => {
              if (this.props.state.newProject.previousButtonVisable) {
                return (
                  <button
                    onClick={() => {
                      this.props.stateHandler.newProjectScreenPrevious(
                        this.props.updateState
                      );
                    }}
                  >
                    Vorige
                  </button>
                );
              }
              return <div></div>;
            })()}
            <button
              onClick={() => {
                this.props.stateHandler.newProjectScreenNext(
                  this.props.updateState
                );
              }}
            >
              {this.props.state.newProject.nextButtonVisable
                ? "Volgende"
                : "Opslaan"}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
